import axios from "axios";

const serverHostname = "https://webapi.dinbingo.no";

/**
 * Fetches tickets for a given bingo ID and card ID.
 * @param {number} HallID - The bingo hall ID.
 * @param {number} CardID - The card ID.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - The response data from the server.
 */
export async function myTickets(HallID, token) {
  try {
    const response = await axios.get(`${serverHostname}/wap/myTickets`, {
      params: {
        bingoId: parseInt(HallID),
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.userTickets;
  } catch (error) {
    console.error("Error fetching tickets:", error);
    throw error;
  }
}

/**
 * Buys a ticket.
 * @param {number} HallID - The bingo hall ID.
 * @param {number} CardID - The card ID.
 * @param {number} amount - The amount to buy.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - The response data from the server.
 */
export async function buyTicket(HallID, systemId, amount, token) {
  try {
    const response = await axios.post(
      `${serverHostname}/wap/buyTicket`,
      {
        bingoId: parseInt(HallID),
        systemId: parseInt(systemId),
        amount: parseFloat(amount),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error buying ticket:", error);
    throw error;
  }
}

/**
 * Adds money to a ticket.
 * @param {number} bingoId - The bingo hall ID.
 * @param {number} systemId - The system ID.
 * @param {number} userCardId - The user card ID.
 * @param {number} ticketNr - The ticket number.
 * @param {number} amount - The amount to add.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - The response data from the server.
 */
export async function addMoneyToTicket(
  bingoId,
  systemId,
  ticketNr,
  amount,
  token
) {
  try {
    const response = await axios.post(
      `${serverHostname}/wap/addMoneyToTicket`,
      {
        bingoId: parseInt(bingoId),
        systemId: parseInt(systemId),
        ticketNr: ticketNr,
        amount: parseFloat(amount),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding money to ticket:", error);
    throw error;
  }
}

/**
 * Closes a ticket.
 * @param {number} ticketId - The ticket ID.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - The response data from the server.
 */
export async function closeTicket(bingoId, systemId, ticketNr, token) {
  try {
    const response = await axios.post(
      `${serverHostname}/wap/closeTicket`,
      {
        systemId: parseInt(systemId),
        bingoId: parseInt(bingoId),
        ticketNr: ticketNr,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error closing ticket:", error);
    throw error;
  }
}

/**
 * Makes a QR request.
 * @param {Object} data - The data to send in the request body.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - The response data from the server.
 */
export async function qrRequest(data, token) {
  try {
    //let serverHostname2 = 'http://localhost:4000';
    const response = await axios.post(
        `${serverHostname}/wap/qrRequest`,
        {
          qrData: data
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error making QR request:", error);
    throw error;
  }
}


