<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--500" no-body>
          <div class="m-4">
            <div v-if="loading" class="spinner"><i class="fa fa-spinner fa-pulse fa-fw"></i> Laster...</div>
<div v-else v-html="responseText"></div>



          </div>
<div class="d-flex justify-content-center mb-4" style="min-height: 45px">
  <base-button v-show="responseText" type="primary" @click="$router.push('/mypage')">
    OK
  </base-button>
</div>        </card>
      </div>
    </section>
  </div>
</template>

<script>
import { qrRequest } from '@/wapFunctions';

export default {
  data() {
    return {
      loading: false,
      responseText: '',
    };
  },
  methods: {
    async fetchQrRequest() {
      this.loading = true;
      try {
        const data = this.$route.params.data;

        console.log('data', data);

        const response = await qrRequest(data, this.$store.state.idToken);
        this.responseText = response?.qrResponse?.message.split('\\n').join('<br />');
        // convert text to utf-8
      } catch (error) {
        console.error('Error fetching QR request:', error);
        this.responseText = 'Det skjedde en feil ved henting av data.';
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchQrRequest();
  },
};
</script>

<style>
.spinner {
  font-size: 22px;
  text-align: center;
}
</style>