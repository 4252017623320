import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios-auth'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  data: {
    logouttimer: null,
    pullerInterval: null,
  },
  state: {
    idToken: null,
    userId: null,
    name: null,
    noBankID: false,
    ownerFilter: null,
    messages: null,
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
      state.name = userData.name
      state.noBankID = userData.noBankID
    },
    updateMessages (state, mObj) {
      state.messages = mObj
    },
    clearAuthData (state) {

      state.idToken = null
      state.userId = null

      localStorage.removeItem('token')
      localStorage.removeItem('expirationDate')
      localStorage.removeItem('noBankID')
      localStorage.removeItem('name')
      localStorage.removeItem('userId')

      clearTimeout(this.logouttimer)
    }
  },
  actions: {
    setLogoutTimer ({commit}, expirationTime) {

     this.logouttimer = setTimeout(() => {
        commit('clearAuthData')
        router.push('/login')
      },expirationTime);

    },startMessagePuller ({commit}) {

      this.logouttimer = setInterval(() => {

        return axios.post('/halls/messages')
        .then(res => { //console.log(res)
          commit('updateMessages', res.data.messages)
        })
       },30000);

       axios.post('/halls/messages')
        .then(res => { //console.log(res)
          commit('updateMessages', res.data.messages)
        })
 
     },
    login ({commit, dispatch}, authData) {

      return axios.post('/user/login', {
        username: authData.username,
        password: authData.password
        })
      .then(res => { console.log(res)
        
      commit("authUser", {
        token: res.data.token,
        userId: res.data.userId,
        name: res.data.userFirstname,
        noBankID: res.data.noBankID
      })
        dispatch('setLogoutTimer', res.data.expiresIn *1000)
        const now = new Date()
        const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
        localStorage.setItem('token',res.data.token)
        localStorage.setItem('expirationDate', expirationDate)
        localStorage.setItem('noBankID', res.data.noBankID)
        localStorage.setItem('name', res.data.userFirstname)
        localStorage.setItem('userId', res.data.userId)

        return 1
      })
      .catch(error => { console.log(error) 
      
        return 0
      })

      
    },
    tryAutoLogin({commit, dispatch}) {
      const token = localStorage.getItem('token')
      if(!token) {
        return
      }

      const expirationDate = new Date(localStorage.getItem('expirationDate'))
      const now = new Date()

      if(now >= expirationDate) {
        return
      }

      const _noBankID = localStorage.getItem('noBankID') == 'true' ? true : false

      commit("authUser", {
        token: token,
        userId: localStorage.getItem('userId'),
        name: localStorage.getItem('name'),
        noBankID: _noBankID
      })
      dispatch('setLogoutTimer', expirationDate - now)
      console.log(expirationDate - now)
      console.log("Autologin");



    },
    logout ({commit}) {

      commit('clearAuthData')

    }


  },
  getters: {
    isAuthenticated (state) {
      return state.idToken !== null
    },
    getMessages(state) {

      return state.messages

    }
  }
})